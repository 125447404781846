import { getImage } from '../cases/adapter';
import type { IBattlepassApiLevel, IBattlepassApiLevelBalance, IBattlepassApiLevelCase } from '../battlepass/api.types';
import type { IRewardPopupAPIDailyCase, IRewardPopupAPIData } from './api.types';
import {
  ERewardPopupTypes,
  ERewardPopupRewardTypes,
  type IRewardPopupBattlepassCase,
  type IRewardPopupBattlepass,
  type IRewardPopupDaily,
  type TRewardPopupVariant,
  type IRewardPopupBattlepassBalance,
} from './client.types';
import type { TPossibleNull } from '~/types/Shared.types';

export const transform = (apiData: IRewardPopupAPIData): TRewardPopupVariant[] => {
  const result: TRewardPopupVariant[] = [];

  for (const challenge of apiData.chaleng.levels) {
    const transformedChallenge = getBattlepassLevel(challenge);
    if (!transformedChallenge) continue;

    result.push(transformedChallenge);
  }

  for (const dailyCase of apiData.dailyCase) {
    result.push(getDailyCase(dailyCase));
  }

  return result;
};

const getBattlepassLevelBalance = (
  balanceData: IBattlepassApiLevelBalance,
): TPossibleNull<IRewardPopupBattlepassBalance> => {
  if (balanceData.coins) {
    return {
      amount: balanceData.coins,
      type: ERewardPopupRewardTypes.COINS,
    };
  }

  if (balanceData.money) {
    return {
      amount: balanceData.money,
      type: ERewardPopupRewardTypes.MONEY,
    };
  }

  return null;
};

const getBattlepassLevelCase = (caseData: IBattlepassApiLevelCase): IRewardPopupBattlepassCase => {
  return {
    amount: caseData.amount,
    image: getImage(caseData.img),
    link: caseData.url,
    name: caseData.name,
    type: ERewardPopupRewardTypes.CASE,
  };
};

const getBattlepassLevel = (challenge: IBattlepassApiLevel): TPossibleNull<IRewardPopupBattlepass> => {
  let reward = null;

  if (challenge.balanceData) {
    reward = getBattlepassLevelBalance(challenge.balanceData);
  }

  if (challenge.caseData && challenge.caseData.length > 0) {
    reward = getBattlepassLevelCase(challenge.caseData[0]);
  }

  if (!reward) return null;

  return {
    id: challenge.level,
    level: challenge.level,
    reward,
    type: ERewardPopupTypes.BATTLEPASS,
  };
};

const getDailyCase = (dailyCase: IRewardPopupAPIDailyCase): IRewardPopupDaily => {
  let level = 0;

  /* 
    Из урла дейли-кейса вытаскиваем место последнего знака "_"
    После него идёт уровень кейса
  */
  const levelDelimeterIndex = dailyCase.name.lastIndexOf('_');
  if (levelDelimeterIndex !== -1) {
    level = +dailyCase.name.slice(levelDelimeterIndex + 1) || 0;
  }

  return {
    id: dailyCase.id,
    image: getImage(dailyCase.img),
    level,
    link: dailyCase.name,
    type: ERewardPopupTypes.DAILY,
  };
};
