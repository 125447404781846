import { transform } from '../Types/freeCases/adapter';
import type { ICasesItem } from '../Types/cases/client.types';

import FreeCasesIcon from '~/assets/images/mainPage/free-cases-icon.png';
import { useAuthStore } from '~/features/authentication/store/authorization';

export const useFreeCasesStore = defineStore('mainPage/freeCases', () => {
  const {
    $api: { mainPage: MainPageService },
    $i18n: { t },
  } = useNuxtApp();

  /* Imports */
  /* Стор авторизации ( для исключения лишних запросов ) */
  const authStore = useAuthStore();

  /* Const */
  /* Список кейсов */
  const cases = ref<ICasesItem[]>([]);

  /* Getters */
  /* Настройки названия секции */
  const titleOptions = computed(() => ({
    image: FreeCasesIcon,
    lineColor: 'linear-gradient(270deg, #DDC059 0%, rgba(17, 7, 34, 0.00) 100.67%)',
    text: t('mainPage.freeCases.forYou'),
    textColor: 'linear-gradient(180deg, #FFF 17.86%, #DDC059 78.57%)',
  }));

  /* Methods */
  /* Получение данных о бесплатных кейсах */
  const fetch = async () => {
    if (!authStore.isAuth) return;

    try {
      const data = await MainPageService.getFreeCases();
      const transformedData = transform(data, t);

      cases.value = transformedData;
    } catch {}
  };

  return {
    cases,
    fetch,
    titleOptions,
  };
});
