import { transform } from '../Types/rewardPopup/adapter';
import type { TRewardPopupVariant } from '~/features/mainPage/Types/rewardPopup/client.types';
import { useAuthStore } from '~/features/authentication/store/authorization';

export const useRewardsStore = defineStore('mainPage/rewards', () => {
  const {
    $api: { mainPage: MainPageService },
  } = useNuxtApp();

  /* Imports */
  /* Стор авторизации */
  const authStore = useAuthStore();

  /* const */
  /* Массив наград */
  const rewards = ref<TRewardPopupVariant[]>([]);
  /* Просмотренные награды */
  const viewedBattlepass = ref<number[]>([]);
  const viewedDaily = ref<number[]>([]);

  /* actions */
  /* Метод получения наград */
  const fetch = async () => {
    if (!authStore.isAuth) return;

    try {
      const data = await MainPageService.getInfoPopup();
      rewards.value = transform(data);
    } catch {}
  };

  return {
    fetch,
    rewards,
    viewedBattlepass,
    viewedDaily,
  };
});
