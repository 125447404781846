/* Список доступных вариантов оповещения */
export enum ERewardPopupTypes {
  BATTLEPASS = 'battlepass',
  DAILY = 'daily',
}

/* Список доступных типов наград */
export enum ERewardPopupRewardTypes {
  CASE = 'case',
  COINS = 'coins',
  MONEY = 'money',
}

/* Объект награды-батлпасса, тип - кейс */
export interface IRewardPopupBattlepassCase {
  amount: number;
  image: string;
  link: string;
  name: string;
  type: ERewardPopupRewardTypes.CASE;
}

/* Объект награды-батлпасса, тип - пополнение баланса */
export interface IRewardPopupBattlepassBalance {
  amount: number;
  type: ERewardPopupRewardTypes.COINS | ERewardPopupRewardTypes.MONEY;
}

/* Объект награды-дейли */
export interface IRewardPopupDaily {
  id: number;
  image: string;
  level: number;
  link: string;
  type: ERewardPopupTypes.DAILY;
}

/* Объект награды-батлпасса */
export interface IRewardPopupBattlepass {
  id: number;
  level: number;
  reward: IRewardPopupBattlepassCase | IRewardPopupBattlepassBalance;
  type: ERewardPopupTypes.BATTLEPASS;
}

/* Объект награды в попапе */
export type TRewardPopupVariant = IRewardPopupDaily | IRewardPopupBattlepass;
