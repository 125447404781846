<template>
  <div class="coin-slot">
    <NuxtImg v-if="icon" :src="icon" class="coin-slot__icon" loading="lazy" />
    <span class="coin-slot__amount">{{ amount }}</span>
  </div>
</template>

<script setup lang="ts">
import type { ICoinSlotProps } from './CoinSlot.types';

const props = defineProps<ICoinSlotProps>();

const styleVariables = computed(() => ({
  color: GlobalUtils.CSS.getBackgroundColor(props.color),
}));
</script>

<style scoped lang="scss">
.coin-slot {
  --text-color: v-bind('styleVariables.color');
}
</style>

<style scoped lang="scss" src="./CoinSlot.scss" />
